import ApplicationController from "./application_controller"
import { patch } from "@rails/request.js"

export default class extends ApplicationController {
  static outlets = ["device--action-form"]
  static targets = ["applicationTypeDropdown"]
  static values = {
    url: String
  }

  // Outlet callback triggered when device--action-form is added (connected) to the page.
  deviceActionFormOutletConnected(outlet, element) {
    this.refreshPanelAction(element)
  }

  refreshPanelActions() {
    patch(this.urlValue, {
      body: new FormData(this.element.closest("form")),
      responseKind: "turbo-stream"
    }).then((response) => {
      if (!response.ok) {
        alert(response.response.statusText)
      }
    })
  }

  refreshPanelAction(actionForm) {
    if (this.isPackageRoom) {
      const actionTypeSelect = actionForm.querySelector("select[name*=action_type]")
      const actionType = actionTypeSelect.value
      if (actionType === "delivery" || actionType === "door_pin") {
        // Package room must have delivery and door pin actions
        // so we disable them and hide options to remove and reorder them.
        actionTypeSelect.disabled = true
        actionForm.querySelector(".device-action-form__actions").classList.add("hidden")
        // Labels for required package room actions are not editable
        actionForm.querySelector("input[name*=label]").readOnly = true
      } else if (actionType === "call" || actionType === "help") {
        // Additional action (call or help) can be removed but can not be
        // reordered so we can remove reorder buttons.
        actionForm.querySelector("[data-action*=moveUp]").classList.add("hidden")
        actionForm.querySelector("[data-action*=moveDown]").classList.add("hidden")
      }
    }
  }

  get isPackageRoom() {
    return this.applicationTypeDropdownTarget.value === "package_room"
  }
}
