import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = ["resource", "actionsContainer"]

  connect() {
    if (!this.element.dataset.bindingDefaults) {
      return
    }

    this.bindingDefaults = JSON.parse(this.element.dataset.bindingDefaults)
    this.setActionsForResource()
  }

  setActionsForResource() {
    if (!this.hasResourceTarget || !this.hasActionsContainerTarget) {
      return
    }

    this.actionsContainerTarget.innerHTML = ""

    var selectedActions = []
    if (this.actionsContainerTarget.dataset.selectedActions) {
      selectedActions =
        JSON.parse(this.actionsContainerTarget.dataset.selectedActions)
    }

    const objectName = this.actionsContainerTarget.dataset.baseObjectName

    const actions = this.bindingDefaults.find(
      ele => ele.resource_type === this.resourceTarget.value
    ).actions

    for(const i in actions) {
      const selected = selectedActions.includes(actions[i])

      this.actionsContainerTarget.appendChild(
        this.buildActionCheckbox(actions[i], objectName, selected)
      )
    }
  }

  buildActionCheckbox(action, objectName, selected) {
    const container = document.createElement("div")
    container.classList.add("checkbox")

    const label = document.createElement("label")
    const checkbox = document.createElement("input")
    checkbox.type = "checkbox"
    checkbox.name = `${objectName}[actions][${action}]`
    if (selected) {
      checkbox.checked = true
    }

    const text = document.createTextNode(action)

    label.appendChild(checkbox)
    label.appendChild(text)
    container.appendChild(label)

    return container
  }

  delete(event) {
    event.preventDefault()
    this.element.remove()
  }
}
