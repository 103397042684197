import ApplicationController from "../application_controller"
import flatpickr from "flatpickr"

export default class extends ApplicationController {
  static targets = ["acodMode", "durationSelect", "startsAt"]

  connect() {
    this.reinitializeFlatpickr()
    this.updateDurationOptions()
  }

  changeMode = () => {
    this.reinitializeFlatpickr()
    this.updateDurationOptions()
  }

  updateDurationOptions() {
    const mode = this.acodModeTarget.value
    let options = []

    switch(mode) {
      case "resident_code":
        this.durationSelectTarget.disabled = true
        options = [{ value: "1", label: "1 Year" }]
        break
      case "short_term_code":
        this.durationSelectTarget.disabled = false
        options = Array.from({ length: 31 }, (_, i) => ({ value: `${i + 1}d`, label: `${i + 1} Day${i > 0 ? "s" : ""}` }))
        break
      case "visitor_code":
        this.durationSelectTarget.disabled = false
        options = [2, 4, 6, 8].map(hours => ({ value: `${hours}h`, label: `${hours} Hours` }))
        break
      default:
        this.durationSelectTarget.disabled = false
    }

    this.setSelectOptions(this.durationSelectTarget, options)
  }

  setSelectOptions(select, options) {
    select.innerHTML = options.map(({ value, label }) => `<option value="${value}">${label}</option>`).join("")
  }

  reinitializeFlatpickr() {

    const enableTime = this.acodModeTarget.value === "visitor_code"

    if (this.flatpickrInstance) {
      this.flatpickrInstance.destroy()
    }
    this.flatpickrInstance = flatpickr(this.startsAtTarget, {
      enableTime: enableTime,
      noCalendar: false,
      dateFormat: "Y-m-d H:00",
      altFormat: enableTime ? "F j, Y H:00" : "F j, Y",
      altInput: true,
      minDate: new Date().setMinutes(0, 0, 0),
      time_24hr: false,
      minuteIncrement: 60,
    })
  }
}
