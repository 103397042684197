import ApplicationController from "./application_controller"
import { parse, format } from "date-fns"

export default class extends ApplicationController {
    static targets = [
        "hourSelection", "startsAt", "startsAtDate"
    ]
    static values = {
        availabilities: Object,
        configuration: Object,
        timeZone: Number,
        buildingTime: String,
        newRecord: Boolean,
        buildingHandle: String,
        availabilitiesUnits: Array,
        selectedUnits: Array
    }


    connect() {
        const controller = this

        $("select.selectize-unit-multiselect").selectize(
            {
                plugins: ["remove_button"],
                allowEmptyOption: true,
                placeholder: "Any Available Tour Compartment",
                onChange: (element) => {
                    this.fetchTimeSlotsDisable(controller, element)
                },
                onInitialize: () => {
                    $(".selectize-input input[placeholder]").attr("style", "width: 100%;")
                }
            }
        )
        this.setDates()
    }

    changeStartDate() {
      this.startsAtTarget.value = ""
      this.setDates()
    }

    getCurrentTime()
    {
        const time = new Date()
        const tzDifference = this.timeZoneValue * 60 + time.getTimezoneOffset()
//convert the offset to milliseconds, add to targetTime, and make a new Date
        return new Date(time.getTime() + tzDifference * 60 * 1000)
    }

    // this function converts date string(2022-07-12T03:32:43-04:00) into "HH:mm" value
    getBuildingCurrentTime(time) {
        const b = time.split(/\D+/)
        return b[4] + ":" + b[5]
    }

    getMinutes(hour_string) {
        const a = hour_string.split(":") // split it at the colons


        return (+a[0]) * 60 + (+a[1])
    }

    getHours(minutes) {
        const hours = (minutes / 60)
        let rhours = Math.floor(hours)
        const aminutes = (hours - rhours) * 60
        let rminutes = Math.round(aminutes)
        if (this.configurationValue.slot_duration_minutes > 30) {
            rminutes = 0
        }
        if (rminutes < 10) {
            rminutes = "0" + rminutes
        }
        if (rhours < 10) {
            rhours = "0" + rhours
        }
        return rhours + ":" + rminutes


    }

    getISODate(date) {
        /* This is to make sure toISOString doesn't change the date because it works with UTC */
        return (new Date(date.getTime() - date.getTimezoneOffset() * 60000)).toISOString().substring(0, 10)
    }

    prepareTakenSlots() {
        const takenSlots = {}
        for (const [key, value] of Object.entries(this.availabilitiesValue.scheduled_slots)) {
            if (takenSlots[key.substring(0, 16)]) {
                takenSlots[key.substring(0, 16)] += value
            } else {
                takenSlots[key.substring(0, 16)] = value
            }
        }

        for (const [key, value] of Object.entries(this.availabilitiesValue.reserved_slots)) {
            if (takenSlots[key.substring(0, 16)]) {
                takenSlots[key.substring(0, 16)] += value
            } else {
                takenSlots[key.substring(0, 16)] = value
            }
        }


        for (const time of this.availabilitiesUnitsValue) {
            takenSlots[time.replace("T", " ").substring(0, 16)] = this.configurationValue.number_of_simultaneous_bookings
        }


        return takenSlots
    }

    setDates() {
        const weekday = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]

        const selectedDate = new Date(this.startsAtDateTarget.value)
        const currentDate = new Date()

        const startTime = this.getCurrentTime()
        const startDay = weekday[selectedDate.getDay()]
        const startHourInMinutes = startTime.getHours()*60
        const minutesNow = startTime.getHours()*60+startTime.getMinutes()
        const buildingCurrentTime = this.getBuildingCurrentTime(this.buildingTimeValue)
        const buildingTimeInMinutes = this.getMinutes(buildingCurrentTime)

        const savedDate = this.startsAtTarget.value.substring(0,10)
        const savedTime = this.startsAtTarget.value.substring(11,16)

        const takenSlots = this.prepareTakenSlots()

        const avails = this.availabilitiesValue.weekday_availabilities[startDay]
        const daySlots = {}
        const controller = this

        let selectedSharedKeyUnits = false

        if (controller.configurationValue.key_locker_in_use) {
            if (controller.selectedUnitsValue.length === 0) {
                selectedSharedKeyUnits = true
            } else {
                selectedSharedKeyUnits = controller.configurationValue.tour_units.some((unit) => {
                    return unit.shared_key_unit && controller.selectedUnitsValue.includes(unit.id)
                })
            }
        }

        if (avails) {
            avails.forEach(avail => {
                const startMinute = controller.getMinutes(avail[0])
                const endMinute = controller.getMinutes(avail[1])

                let minute
                for (minute = startMinute; minute < endMinute; minute += controller.configurationValue.slot_duration_minutes) {
                    let slotActive = 1
                    const hours = this.getHours(minute)

                    const currentSlot = controller.getISODate(selectedDate) + " " + hours
                    if (selectedDate < startTime) {
                        if (minute < startHourInMinutes) {
                            slotActive = 0
                        }
                        if ((new Date(selectedDate)).setHours(0, 0, 0, 0) < (new Date(startTime)).setHours(0, 0, 0, 0)) {
                            slotActive = 0
                        }
                        if (minute+controller.configurationValue.slot_duration_minutes <= minutesNow ) {
                            slotActive = 0
                        }
                    }

                    if (selectedSharedKeyUnits && takenSlots[currentSlot] >= controller.configurationValue.shared_key_compartment_count) {
                        slotActive = 0
                    }
                    if (takenSlots[currentSlot] >= controller.configurationValue.number_of_simultaneous_bookings) {
                        slotActive = 0
                    }
                    if (savedDate === controller.getISODate(selectedDate) && controller.getMinutes(savedTime) === minute && !this.newRecordValue) {
                        slotActive = 2
                    }
                    if(buildingTimeInMinutes > minute && selectedDate.toDateString() === currentDate.toDateString()) {
                        slotActive = false
                    }
                    if (slotActive) {
                        daySlots[hours] = slotActive
                    }
                }
            })
        }

        this.renderSlots(daySlots)
    }

    renderSlots(daySlots) {
        this.hourSelectionTarget.innerHTML = ""

        for (const [key, value] of  Object.entries(daySlots)) {

            const hour = this.convert(key, "HH:mm", "h:mm a")
            let className = ""
            if (value === 2) {
                className = "btn-picker-selected"
            }
            this.hourSelectionTarget.insertAdjacentHTML("beforeend",
                `<div class="col-lg-2 col-md-4 col-sm-6 col-xs-6">
                        <div class="hourcontrol" data-action="click->tour-schedule-form#clickTime">
                            <a class="btn btn-picker ${className} js-time-pick">${hour}</a>
                        </div>
                    </div>`)
        }
        if (this.hourSelectionTarget.innerHTML === "") {
            this.hourSelectionTarget.innerHTML = "No Tour Times Available"
        }
    }

    clickTime(event) {
        const selectedDate = new Date(this.startsAtDateTarget.value)

        const hours = this.convert(event.target.innerText, "h:mm a", "HH:mm")
        document.querySelectorAll(".js-time-pick").forEach(function(element) {
            element.classList.remove("btn-picker-selected")
        })

        event.target.classList.add("btn-picker-selected")
        this.startsAtTarget.value = this.getISODate(selectedDate) + "T" + hours
    }


    fetchTimeSlotsDisable(controller, units) {
        const requestOrigin = location.origin.replace(/app|user/, "api")
        const endpoint = `/tours/${this.buildingHandleValue}/unit_availabilities`

        return $.ajax({
            type: "POST",
            url: requestOrigin + endpoint,
            accept: "json",
            dataType: "json",
            data: {
                tour_unit_ids: units,
                local_time: true
            },
            xhrFields: {
                withCredentials: true
            },
            success: (successData) => {
                controller.availabilitiesUnitsValue = successData.unavailableUnitTimes
                if (Array.isArray(units)) {
                    controller.selectedUnitsValue = units
                } else {
                    controller.selectedUnitsValue = []
                }
                controller.setDates()
            },
            error: () => { alert("Fetching available compartments failed") }
        })
    }

    convert(value, fromFormat, toFormat) {
      return format(parse(value, fromFormat, new Date()), toFormat)
    }
}
