import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = ["headersContainer", "headerTemplate"]

  addHeader(event) {
    event.preventDefault()

    if (!this.hasHeaderTemplateTarget || !this.hasHeadersContainerTarget) {
      return
    }

    const headerTemplateHtml = this.headerTemplateTarget.innerHTML
    var newHeader = document.createElement("div")
    newHeader.innerHTML = headerTemplateHtml
    newHeader = newHeader.firstChild

    this.headersContainerTarget.appendChild(newHeader)
  }

  deleteHeader(event) {
    event.preventDefault()

    const headerRow = event.target.closest(".row")

    if (!headerRow) {
      return
    }

    headerRow.remove()
  }
}
