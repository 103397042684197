import ApplicationController from "../application_controller"

export default class NewVirtualKeyFormController extends ApplicationController {
  static targets = [
    "kindPicker", "customOTContainer", "recurringContainer", "customOTField",
    "recurringField", "compartmentSelect", "startsAtField", "endsAtField", "form",
    "cancel", "noCompartmentsAvailable", "submitButton", "startDateField",
    "endDateField", "timeFromField", "timeToField", "returnPolicyCheckbox"
  ]
  static values = { keyLockerId: String }
  static recurringFields =  ["startDateField", "endDateField", "timeFromField", "timeToField"]

  connect() {
    this.toggleKind(this.kindPickerTarget.value)
    this.determineCompartments()
  }

  handleKindChange(e) {
    this.toggleKind(e.target.value)
  }

  someRecurringFieldsEmpty() {
    return NewVirtualKeyFormController
            .recurringFields
            .some((field) =>  this[`${field}Target`].value == "")
  }

  determineCompartmentsForRecurring() {
    if(this.someRecurringFieldsEmpty() && this.kindPickerTarget.value == "recurring") {
      this.compartmentSelectTarget.disabled = true
    } else {
      const startsAt = `${this.startDateFieldTarget.value} ${this.timeFromFieldTarget.value}`
      const endsAt = `${this.endDateFieldTarget.value} ${this.timeToFieldTarget.value}`
      this.disableCompartmentsInRange(startsAt, endsAt)
    }
  }

  determineCompartments() {
    if ((this.startsAtFieldTarget.value == "" ||
      this.endsAtFieldTarget.value == "") && this.kindPickerTarget.value !== "recurring") {
      this.compartmentSelectTarget.disabled = true
    } else {
      this.disableCompartmentsInRange(this.startsAtFieldTarget.value,  this.endsAtFieldTarget.value)
    }
  }

  disableCompartmentsInRange(startsAt, endsAt) {
    this.compartmentSelectTarget.disabled = false
    this.fetchCompartmentsToDisable(startsAt, endsAt)
  }

  fetchCompartmentsToDisable(startsAt, endsAt) {
    const requestOrigin = location.origin.replace(/app|user/, "api")
    const endpoint = `/internal/key_lockers/${this.keyLockerIdValue}/compartment_availability`

    return $.ajax({
      type: "POST",
      url: requestOrigin + endpoint,
      accept: "json",
      dataType: "json",
      data: {
        from: startsAt,
        to: endsAt
      },
      xhrFields: {
        withCredentials: true
      },
      success: (successData) => {
        const compartmentIdsToDisable = successData.unavailableCompartmentIds
        const noneAvailable = successData.noneAvailable
        // finds option by matching value and disables it
        this.enableAllCompartments()
        if (compartmentIdsToDisable?.data?.length === 0) {
          return
        }
        compartmentIdsToDisable.forEach(
          id => {
            const option = this.compartmentSelectTarget.querySelectorAll(`option[value='${id}']`)[0]
            option.disabled = true
            option.selected = false
          }
        )
        if (noneAvailable) {
          this.noCompartmentsAvailableTarget.classList.remove("hidden")
          this.compartmentSelectTarget.disabled = true
        } else {
          this.noCompartmentsAvailableTarget.classList.add("hidden")
          this.compartmentSelectTarget.disabled = false
        }
      },
      error: () => { alert("Fetching available compartments failed") }
    })
  }

  toggleKind(selectedKind) {
    if (selectedKind === "custom" || selectedKind === "one_time") {
      this.hideContent("recurring")
      this.showContent("customOT")
    } else if (selectedKind === "recurring") {
      this.hideContent("customOT")
      this.showContent("recurring")
      this.enableAllCompartments() // enable all until we implement availability check for recurring keys
      this.compartmentSelectTarget.disabled = false
    }

    const returnPolicyChecbkoxWrapper = this.returnPolicyCheckboxTarget.closest(".checkbox")

    if (selectedKind === "one_time") {
      returnPolicyChecbkoxWrapper.classList.add("hidden")
      this.returnPolicyCheckboxTarget.disabled = true
    } else {
      returnPolicyChecbkoxWrapper.classList.remove("hidden")
      this.returnPolicyCheckboxTarget.disabled = false
    }
  }

  hideContent(targetName) {
    this[`${targetName}ContainerTarget`].classList.add("hidden")
    if (targetName == "recurring") {
      NewVirtualKeyFormController.recurringFields
                                 .map((field) => this[`${field}Target`].disabled = true)
    } else {
      this.startsAtFieldTarget.disabled = true
      this.endsAtFieldTarget.disabled = true
    }

  }

  showContent(targetName) {
    this[`${targetName}ContainerTarget`].classList.remove("hidden")
    if (targetName == "recurring") {
      NewVirtualKeyFormController.recurringFields
                                 .map((field) => this[`${field}Target`].disabled = false)
    } else {
      this.startsAtFieldTarget.disabled = false
      this.endsAtFieldTarget.disabled = false
    }
  }

  enableAllCompartments() {
    this.compartmentSelectTarget
      .querySelectorAll("option").forEach(option => {
        option.disabled = false
      })
  }

  clickCancel(event) {
    event.preventDefault()
    this.formTarget.style.display = "none"
    this.cancelTarget.style.display = "block"
  }

  clickCancelCancel(event) {
    event.preventDefault()
    this.formTarget.style.display = "block"
    this.cancelTarget.style.display = "none"
  }
}
