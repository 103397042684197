import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static values = {
    attachedToBody: Boolean,
    fixModalSelection: Boolean,
    maxItems: Number
  }

  initialize() {
    this.selectizeOptions = {}
  }

  connect() {
    this.enterLoadingState()
    this.initializeSelectize()
  }

  disconnect() {
    this.destroySelectize()
  }

  enterLoadingState() {
    this.element.dataset.selectControllerLoading = true
  }

  leaveLoadingState() {
    this.element.removeAttribute("data-select-controller-loading")
  }

  initializeSelectize() {
    const options = {
      ...this.defaultSelectizeOptions,
      ...this.selectizeOptions
    }

    if (this.attachedToBodyValue) options.dropdownParent = "body"

    $(this.element).selectize(options)

    this.selectize = this.element.selectize
  }

  destroySelectize() {
    if (!this.selectize) return

    this.selectize.destroy()
    this.selectize = null
  }

  valueChanged() {
    const event = new Event("change")
    this.element.dispatchEvent(event)
  }

  focused() {
  }

  blured() {
  }

  selectizeInitialized() {
    this.leaveLoadingState()
  }

  dropdownOpened($dropdown) {
    if (this.attachedToBodyValue) $dropdown[0].style.zIndex = 1060

    if (this.fixModalSelectionValue) {
      document.querySelectorAll(".modal").forEach((modal) => {
        const modalData = $(modal).data("bs.modal")
        if (!modalData) return

        modalData.previousValues = {}
        modalData.previousValues.backdrop = modalData.options.backdrop
        modalData.previousValues.keyboard = modalData.options.keyboard
        modalData.options.backdrop = "static"
        modalData.options.keyboard = false
      })
    }
  }

  dropdownClosed($dropdown) {
    if (this.fixModalSelectionValue) {
      document.querySelectorAll(".modal").forEach((modal) => {
        const modalData = $(modal).data("bs.modal")
        if (!modalData || !modalData.previousValues) return

        // this is a horrendous hack, but Bootstrap 3 gave me no other choice
        setTimeout(
          () => {
            modalData.options.backdrop = modalData.previousValues.backdrop
            modalData.options.keyboard = modalData.previousValues.keyboard
          },
          120
        )
      })
    }
  }

  get defaultSelectizeOptions() {
    const defaultAttributes = ["text", "value", "disabled", "$order"]

    const opts = {
      onInitialize: this.selectizeInitialized.bind(this),
      onChange: this.valueChanged.bind(this),
      onFocus: this.focused.bind(this),
      onBlur: this.blured.bind(this),
      onDropdownOpen: this.dropdownOpened.bind(this),
      onDropdownClose: this.dropdownClosed.bind(this),
      render: {
        option: function (data, escape) {
          const dataAttributes = Reflect.ownKeys(data).filter(element => !defaultAttributes.includes(element))
          const dataAttributesString = dataAttributes.map(attribute => {
            return `data-${attribute}="${escape(data[attribute])}"`
          }).join(" ")
          return `<div class='option' ${escape(dataAttributesString)}>${escape(data.text)}</div>`
        }
      }
    }

    if (this.hasMaxItemsValue) opts.maxItems = this.maxItemsValue

    return opts
  }
}
