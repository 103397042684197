import ApplicationController from "./application_controller"

export default class extends ApplicationController {
    static targets = [
        "availabilities", "template", "form", "units", "templateUnit", "alert", "unitSelection",
        "smsCommunication", "emailCommunication", "uiBothMessaging", "uiSmsOnly", "uiEmailOnly",
        "schedulingPortal", "termsOfService", "schedulingFeatures", "removeAvailability", "resetAvailability", "tourDuration",
        "keyLockerLink"
    ]

  static values = {
    unitCompartments: Object,
    keyLockerSharedKeyCompartmentCounts: Object,
  }

  connect() {
    if (document.querySelectorAll(".nested-fields").length == 0) {
      this.addAvailability(null)
    }


    this.addTourUnitSelectEvents($("input.js-selectize-unit-label:not(.selectized), select.js-selectize-unit-label:not(.selectized)"))
    this.addKeyLockerEvents()
    this.initDayControls()

    $("input.selectize-select-allow-empty:not(.selectized), select.selectize-select-allow-empty:not(.selectized)").selectize({
      allowEmptyOption: true
    })

    this.setTemplates()
    this.renderMessagingRadios()
    this.renderSchedulingPortal()
    this.configureKeyLocker()
  }

  addTourUnitSelectEvents(tourUnitSelect) {
    tourUnitSelect.selectize({
      plugins: {
        dropdown_header: {
          title: "<button class=\"btn btn-primary-outline\">+ Add new unit</button>"
        }
      }
    })

    tourUnitSelect.on("change", (event) => {
      // Pulls out label value from selectized field and updates the hidden field
      $(event.currentTarget).parent()
                            .siblings(".js-hidden-field-unit-label")
                            .val(event.currentTarget.innerText)
    })
  }

  addKeyLockerEvents() {
    const controller = this
    $("input.js-selectize-key-locker, select.js-selectize-key-locker").on("change", (event) => {
        controller.configureKeyLocker()
    })

    $("input.js-selectize-unit-label, select.js-selectize-unit-label").on("change", (event) => {
      const unitStatus = $(".js-unit-status", event.currentTarget.closest("td"))
      controller.renderUnitStatus(unitStatus[0])
    })
  }

  configureKeyLocker() {
    const controller = this
    $(".js-unit-status").each ( function () {
      controller.renderUnitStatus(this)
    })

    const keyLockerId = $(".js-selectize-key-locker").val()

    const link = this.keyLockerLinkTarget.getAttribute("href")

    const newLink = link.replace(/[0-9]+(?!.*[0-9])/, function(match) {
      return parseInt(match, 10), keyLockerId
    })
    this.keyLockerLinkTargets.map( (target) => {
      target.setAttribute("href", newLink)
    })
  }

  renderUnitStatus(element) {
    const keyLockerId = $(".js-selectize-key-locker").val() || this.currentKeyLockerIdValue
    const controller = this
    const keyLockerEnabled = document.getElementById("key_locker").checked
    if (!keyLockerEnabled || !keyLockerId) {
      $(element).hide()
      $(".js-key-locker-unit-button").hide()
      $(".js-tour-unit-selection-keylocker-info").hide()
      return
    } else {
      $(".js-tour-unit-selection-keylocker-info").show()
    }

    $(".js-key-locker-unit-button").show()
    $(element).show()
    $(".status_unassigned", element).show()
    $(".status_assigned", element).hide()
    $(".status_action-needed", element).hide()

    const selectedUnitId = $(".js-selectize-unit-label", element.parentElement)[0].selectize.getValue()
    if (!controller.unitCompartmentsValue[selectedUnitId]) {
      return
    }

    controller.unitCompartmentsValue[selectedUnitId].forEach( compartment => {
      if (compartment.status_new === "in_use") {
        $(".compartment_label", element)[0].innerHTML = compartment.column + compartment.row
        $(".status_assigned", element).show()
        $(".status_unassigned", element).hide()
      } else if (compartment.status_new === "not_setup") {
        $(".status_assigned", element).hide()
        $(".status_unassigned", element).hide()
        $(".status_action-needed", element).show()
      }
    })
  }

  toggleUnits(event) {
    this.setTemplates()
  }

  setTemplates() {
    document.querySelectorAll("[data-template]").forEach(field => {
      if (this.unitSelectionTarget.checked && field.dataset.templateUnits &&
          (field.value === field.dataset.template || field.value === "")) {
        field.value = field.dataset.templateUnits
      }
      if (!this.unitSelectionTarget.checked && field.dataset.templateUnits &&
          (field.value === field.dataset.templateUnits || field.value === "")) {
        field.value = field.dataset.template
      }
      if (field.value === "") {
        field.value = field.dataset.template
      }
    })
  }

  toggleMessaging(event) {
      if (event.target.checked) {
        this.selectEmailOnly()
      } else {
        this.selectNoneMessaging()
      }
      this.renderMessagingRadios()
  }

  renderMessagingRadios(){
      if (this.emailCommunicationTarget.value === "true" && this.smsCommunicationTarget.value === "true") {
          this.uiBothMessagingTarget.checked = true
      } else if (this.smsCommunicationTarget.value === "true") {
        this.uiSmsOnlyTarget.checked = true
      } else if (this.emailCommunicationTarget.value === "true") {
        this.uiEmailOnlyTarget.checked = true
      }

  }


  selectSmsOnly() {
    this.emailCommunicationTarget.value = "false"
    this.smsCommunicationTarget.value = "true"
  }


  selectEmailOnly() {
    this.emailCommunicationTarget.value = "true"
    this.smsCommunicationTarget.value = "false"
  }

  selectBothMessaging() {
    this.emailCommunicationTarget.value = "true"
    this.smsCommunicationTarget.value = "true"

  }

  selectNoneMessaging() {
    this.emailCommunicationTarget.value = "false"
    this.smsCommunicationTarget.value = "false"
  }

  renderSchedulingPortal() {
    const disabled = !this.schedulingPortalTarget.checked
    this.termsOfServiceTarget.classList.toggle("hidden", disabled)
    this.schedulingFeaturesTarget.classList.toggle("hidden", disabled)
    this.schedulingFeaturesAnchors().forEach(element => {
      element.classList.toggle("hidden", disabled)
    })
  }

  schedulingFeaturesAnchors() {
    const start = this.anchorMenuTarget.querySelector("[href='#scheduling_features']").parentElement
    let anchor = start.nextSibling
    const anchors = []
    while (anchor) {
      anchors.push(anchor)
      anchor = anchor.nextSibling
    }
    return anchors
  }

  initDayControls() {
    const dayControls = document.querySelectorAll(".day-control")
    if (dayControls.length === 1) {
      this.removeAvailabilityTargets.forEach((element) => {
        element.classList.add("hidden")
      })
      this.resetAvailabilityTargets.forEach((element) => {
        element.classList.remove("hidden")
      })

    } else {
      this.removeAvailabilityTargets.forEach((element) => {
        element.classList.remove("hidden")
      })
      this.resetAvailabilityTargets.forEach((element) => {
        element.classList.add("hidden")
      })
    }

    for (const dayControl of dayControls) {
      const select = dayControl.getElementsByTagName("select")[0]
      for (const option of select.options) {
        const dayButton = dayControl.querySelector(`[data-day="${option.value}"]`)
        if (option.selected) {
          dayButton.classList.add("btn-primary")
          dayButton.classList.remove("btn-unselected")
        } else {
          dayButton.classList.remove("btn-primary")
          dayButton.classList.add("btn-unselected")
        }
      }
    }
  }

  toggleDay(event) {
    event.target.classList.toggle("btn-primary")
    event.target.classList.toggle("btn-unselected")
    if (event.target.classList.contains("btn-primary")) {
      this.setDayOption(event.target, true)
    } else {
      this.setDayOption(event.target, false)
    }
  }

  setDayOption(target, selected) {
    target.parentElement.getElementsByTagName("select")[0]
      .querySelector(`option[value="${target.dataset.day}"]`).selected = selected
  }

  enableWorkdayTimes(container) {
    container.querySelector("[name$=\"[open_time]\"]").value = "09:00AM"
    container.querySelector("[name$=\"[close_time]\"]").value = "05:00PM"
    const select = container.querySelector(".day-control").getElementsByTagName("select")[0]
    for (const option of select.options) {
      if (option.value === "sun" || option.value === "sat") {
        option.selected = false
      } else {
        option.selected = true
      }
    }
    this.initDayControls()
  }

  addAvailability(event) {
    if (event != null) event.preventDefault()

    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.availabilitiesTarget.insertAdjacentHTML("beforeend", content)
    this.enableWorkdayTimes(this.availabilitiesTarget.lastElementChild)
  }

  removeAvailability(event) {
    event.preventDefault()

    const wrapper = event.target.closest(".nested-fields")

    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = "none"
      wrapper.querySelector(".day-control").classList.remove("day-control")
    }
    this.initDayControls()
  }

  resetAvailability(event) {
    event.preventDefault()
    this.enableWorkdayTimes(event.target.parentElement.parentElement.parentElement)
  }

  addUnit(event) {
    if (event != null) event.preventDefault()

    const content = this.templateUnitTarget.innerHTML.replace(/NEW_RECORD_UNIT/g, new Date().getTime())
    this.unitsTarget.insertAdjacentHTML("beforebegin", content)

    const select = $("input.js-selectize-unit-label:not(.selectized), select.js-selectize-unit-label:not(.selectized)", this.unitsTarget.previousSibling)
    this.addTourUnitSelectEvents(select)
    this.addKeyLockerEvents()
    this.configureKeyLocker()
  }

  removeUnit(event) {
    event.preventDefault()

    const wrapper = event.target.closest(".nested-fields-units")

    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = "none"
    }
  }

  resetToTemplate(event) {
    event.preventDefault()

    const text_field = event.target.closest(".js-text-field").getElementsByTagName("textarea")[0]
    text_field.value = ""
    this.setTemplates()
  }

  sendTestEmail(event) {
    event.preventDefault()

    const text_field = event.target.closest(".js-text-field").getElementsByTagName("textarea")[0]
    const preview_data = {
      preview_email_name: text_field.name,
      preview_email_text: text_field.value,
      preview_email_signature: document.querySelector("textarea[name='tour_configuration[email_signature]']").value,
      preview_email_cc: document.querySelector("input[name='tour_configuration[email_cc]']").value,
      preview_email_reply_to: document.querySelector("input[name='tour_configuration[email_reply_to]']").value,
    }

    $.ajax({
      type: "POST",
      url: this.formTarget.dataset.sendEmailPreviewUrl + "/?building_id=" + this.formTarget.dataset.buildingId,
      accept: "json",
      dataType: "json",
      data: new URLSearchParams(preview_data).toString(),
      xhrFields: {
        withCredentials: true
      },
      success: (response) => {
        this.alertTarget.style.opacity = "100%"
        setTimeout( () => {
        this.alertTarget.style.opacity = "0"
        }, 2000)
      },
      error: (response) => { alert("Preview send failed") }
    })
  }

  previewText(event) {
    event.preventDefault()

    const container = event.target.closest(".js-text-field")
    const preview_data = {
      preview_field_name: container.getElementsByClassName("trix-content")[0].id + "_draft",
      preview_value: container.getElementsByClassName("trix-content")[0].value
    }

    $.ajax({
      type: "POST",
      url: this.formTarget.action,
      accept: "json",
      dataType: "json",
      data: new URLSearchParams(preview_data).toString(),
      success: (response) => {
        window.open(event.target.dataset.previewUrl, "_blank")
      },
      error: (response) => { console.log("Preview save failed", response) }
    })
  }

  previewTourUnits(event) {
    const targetWindow = window.open(event.target.dataset.previewUrl, "_blank")
    window.addEventListener("message", (event) => {
      if (event.data === "previewOpened")
        {targetWindow.postMessage(this.getTourUnitData(), "*")}
    })
  }

  getTourUnitData() {
    const TOUR_UNIT_REGEX = /.*\[tour_units_attributes\]/
    const TOUR_UNIT_INPUT_REGEX = /^.*\[(.*)\]$/
    const TOUR_UNIT_ROW_REGEX = /\d+/
    const formData = new FormData(this.formTarget)
    const tourUnits = {}

    formData.forEach(function(value, key){
      if(key.match(TOUR_UNIT_REGEX)) {
        const tourUnitRow = key.match(TOUR_UNIT_ROW_REGEX)[0]
        if (tourUnits[tourUnitRow] === undefined)
          {tourUnits[tourUnitRow] = {}}
        const tourUnitElement = key.match(TOUR_UNIT_INPUT_REGEX).pop()
        tourUnits[tourUnitRow][tourUnitElement] = value
      }
    })

    return tourUnits
  }

  fieldInvalid(event) {
    console.log(event)
    event.target.setCustomValidity("Duration must be " + event.target.min + " or " + event.target.max + " minutes")
  }

  get anchorMenuTarget() {
    return document.querySelector(".anchor-menu")
  }
}
